const titles = {
    action: "Action",
    addSuccessfully: "Added Successfully",
    updateSuccessfully: "Updated Successfully",
    errorMessage: "Ops, something went wrong, try again",
    deletedSuccess: "deleted successfully",
    otpVerifiedMsg: "OTP Verified Successfully !",
    otpSentSuccessMsg: "OTP Sent Successfully !",
    registerSuccessMsg: "Register Successfully !",
    areYouSureWantToLogout: "Are you sure you want to logout?",
    successLogin: "You have been successfully logged in",
    update: "Update",
    save: "Save",
    item: "Item",
    profile: "Profile",
    dashboard: "Dashboard",
    enterToSearch: "Enter to search",
    email: "Email",
    phone: "Phone",
    openVolunteering: "Open Volunteering",
    myVolunteerBasket: "My Volunteer Basket",
    newHelpRequest: "New Help Request",
    userManagement: "User Management",
    volunteersManagement: "Volunteers Management",
    notificationLog: "Notification Log",
    aboutUs: "About Us",
    instructions: "Instructions",
    regulations: "Regulations",
    accessibilityStatement: "Accessibility Statement",
    fullName: "Full Name",
    type: "Type",
    registrationDate: "Registration Date",
    disability: "Disability",
    residentialAddress: "Residential Address",
    currentAddress: "Current Address",
    alerts: "Alerts",
    actions: "Actions",
    all: "All",
    volunteer: "Volunteer",
    user: "User",
    welcomeMessage: "Welcome to the purple vest app of the Israel Accessibility Association - to help people with disabilities in emergencies. Here you can help people with disabilities all over the country. Your help will significantly help the mission of the purple vest - to leave no one behind!",
    enterPhoneNumber: "Enter a phone number to receive a code via SMS",
    enterVerificationCode: "Enter the verification code",
    verifyCode: "Verify Code",
    getCode: "Get Code",
    registerAs: "Register as",
    clear: "Clear",
    editingMyProfile: "Editing my profile",
    exitFromSystem: "Exit from system",
    refresh: "Refresh",
    disableCategoryOption: "Disable below helping request category option known as your category request already under process or opened",
    chooseHelpType: "To get started, choose the type of help you're requesting:",
    toNextStep: "To the next step",
    nextStep: "Next step:",
    needRideTo: "I need a ride to",
    iAmFrom: "I am from",
    originAddressLonger: "Your origin address must be longer than 5 characters.",
    destinationAddressLonger: "Your Destination address must be longer than 5 characters.",
    wantToBeThereOn: "I want to be there on",
    selectFutureDate: "Please select a future date.",
    requestDetailsDescription: "Details and description of the request",
    descriptionLonger: "Your description must be longer than 5 characters.",
    untilRequestRelevant: "Until when the request is relevant",
    personalDetails: "Personal details",
    fullNameLonger: "Your full name must be longer than 5 characters.",
    phoneLonger: "Your phone must be longer than 5 characters.",
    residentialAddressLonger: "Your Residential Address must be longer than 5 characters",
    createNewHelpRequest: "Create new help request",
    enterValidPhoneNumber: "Enter a valid phone number",
    sentTo: "Sent to",
    date: "Date",
    time: "Time",
    channel: "Channel",
    content: "Content",
    usePrevious: "Use Previous",
    useCurrent: "Use Current",
    status: "Status",
    sendTo: "Send To",
    category: "Category",
    pendingApplicationUserRequestManagement: "Pending Application User Request Management",
    perPage: "Per Page",
    arrivalAddress: "Arrival Address",
    wayForCommunication: "Preferred way communication",
    whatsApp: "WhatsApp",
    welcomeDashboard: "Welcome Dashboard",
    iNeedARideTo: "I need a ride to",
    iWantToBeThereOn: "I want to be there on",
    at: "at",
    detailsAndDescriptionOfRequest: "Details and description of the request",
    toTheNextStep: "To the next step",
    goToNextStep: "Go to the next step",
    referenceDescription: "Reference description",
    comments: "Comments",
    relevantUntil: "Relevant until",
    sorryICantHelpThisTime: "Sorry, I can't help this time",
    imReadyAccept: "I'm ready to accept",
    appliedFilters: "Applied Filters",
    filtering: "Filtering",
    showVolunteers: "Show Volunteers",
    cancel: "Cancel",
    byCategory: "By Category",
    byTown: "By Town",
    accessibilityAndDisabilityInfo: "Information about accessibility and disability need",
    goBack: "Go Back",
    details: "Details",
    youCanContactMeAt: "You can contact me at",
    deletedSuccessMsg: "has been deleted successfully",
    yes: "Yes",
    registerToHelp: "Register to help",
    joinUs: "Join us",
    role: "Role",
    switchAs: "Switch as",
    submit: "Submit",
    updateRoleMsg: "Role has been updated successfully"
};

export default {
    locale: "en-US",
    messages: {
        title: titles
    },
};

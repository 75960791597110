<template>
    <div class="main-block volunteer-basket">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.myVolunteerBasket') }}</h4>
            <b-button
                variant="outline-primary"
                class="gap-1 d-flex align-items-center fs-14 ms-auto position-relative"
                v-b-modal.modal-1>{{ $t('title.filtering') }}
                <b-icon icon="filter" class="icon-style"></b-icon
                >
                <b-badge pill variant="primary" class="position-absolute"
                >2<span class="sr-only">{{ $t('title.appliedFilters') }}</span></b-badge
                >
            </b-button
            >
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" v-if="user">
                <b-card-group columns>
                    <RequestCard
                        v-for="(card, index) in cards"
                        :key="index"
                        :index="index + 1"
                        :selected="true"
                        :item="card"
                        :userId="user.id" v-on:refresh="getList"
                        :flag="true"
                    />
                </b-card-group>
            </div>
        </div>

        <b-modal
            id="modal-1"
            :title="$t('title.filtering')"
            :centered="true"
            :ok-title="$t('title.showVolunteers')"
            :cancel-title="$t('title.cancel')"
            cancel-variant="outline-primary"
        >
            <b-form @submit.stop.prevent="onSubmit" class="col-12 mx-auto p-0">
                <div class="mb-3">
                    <label for="filtering">{{ $t('title.byCategory') }}:</label>
                    <treeselect :multiple="false" v-model="filtering" :options="dropdowns.categories"
                                id="filtering"></treeselect>
                </div>
                <div class="mb-3">
                    <label for="town">{{ $t('title.byTown') }}:</label>
                    <v-select v-model="town" :options="town_options" id="town"></v-select>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import RequestCard from '@/components/RequestCard.vue';
import { request } from "@/Util/Request";
import qs from "qs";
import { mapState } from "vuex";

export default {
    components: {
        RequestCard,
    },
    data() {
        return {
            filtering: null,
            town: '',
            cards: [],
            dropdowns: {
                categories: []
            },
            town_options: [
                { value: '1', label: 'Tel-Aviv' },
                { value: '2', label: 'Ashkelon' },
                { value: '3', label: 'Beersheba' },
            ],
            userId: null
        };
    },
    computed: {
        ...mapState(['user'])
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const { categories } = response;

                this.dropdowns.categories = categories;
                this.getList();

            } catch (error) {
            }
        },
        async getList() {
            try {

                const params = {
                    "filters": {
                        is_approved: 1
                    }
                };


                const response = await request({
                    method: 'get',
                    url: `/volunteer/application-users`,
                    params: params,
                    paramsSerializer: ( (params) => qs.stringify(params) ),
                });

                const { data } = response;
                this.cards = _.map(data, (i) => {
                    return i.application;
                });

            } catch (error) {
            }
        },
    }
};
</script>

<template>
    <div class="w-100 d-flex mt-3">
        <div class="col-12">
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-6">
                                <label for="full_name">{{ $t('title.fullName') }}</label>
                                <b-form-input name="full_name" v-model="formFields.full_name"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('full_name')">
                                    {{ formErrors.first('full_name') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="email">{{ $t('title.email') }}</label>
                                <b-form-input name="email" v-model="formFields.email" type="email"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('email')">
                                    {{ formErrors.first('email') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-12 col-md-6">
                                <label for="phone">{{ $t('title.phone') }}</label>
                                <b-form-input name="phone" v-model="formFields.phone" type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('phone')">
                                    {{ formErrors.first('phone') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-12">
                                <label for="residential_address">{{ $t('title.residentialAddress') }}</label>
                                <input type="radio" class="ml-2" name="address_type" value="residential" v-model="formFields.address_type">
                                <input
                                    type="text"
                                    v-model="formFields.residential_address"
                                    @input="search('residential_address')"
                                    @focus="onFocus('residential_address')"
                                    placeholder="Search location..."
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'residential_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('residential_address')">
                                    {{ formErrors.first('residential_address') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 mt-3">
                                <label for="arrival_address">{{ $t('title.arrivalAddress') }}</label>
                                <input type="radio" class="ml-2" name="address_type" value="arrival" v-model="formFields.address_type">
                                <input
                                    type="text"
                                    v-model="formFields.arrival_address"
                                    @input="search('arrival_address')"
                                    @focus="onFocus('arrival_address')"
                                    placeholder="Search location..."
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.arrival_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'arrival_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                            <b-spinner small variant="primary" type="grow"
                                                       label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>

                                <b-form-invalid-feedback
                                    :state="!formErrors.has('arrival_address')">
                                    {{ formErrors.first('arrival_address') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 mt-3">
                                <label>
                                    {{ $t('title.wayForCommunication') }}
                                </label>
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="formFields.is_phone"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    {{ $t('title.phone') }}
                                </b-form-checkbox>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('is_phone')">
                                    {{ formErrors.first('is_phone') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    id="checkbox-2"
                                    v-model="formFields.is_wp"
                                    name="checkbox-2"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    {{ $t('title.whatsApp') }}
                                </b-form-checkbox>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('is_wp')">
                                    {{ formErrors.first('is_wp') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="mt-3">
                    <b-col sm="12 mb-3">
                        <b-button type="submit">{{ $t('title.update') }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </div>
</template>


<script>
import Error from "@/Util/Error";
import { request } from "@/Util/Request";
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import destinationMixin from "@/Util/destinationMixin";

const simulateAsyncOperation = fn => {
    setTimeout(fn, 2000);
};

const FORM_STATE = {
    email: null,
    phone: null,
    full_name: null,
    residential_address: null,
    arrival_address: null,
    disability_id: null,
    is_phone: 0,
    is_wp: 0,
    address_type: 'residential'
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            address: null,

            options: [],
        };
    },
    mounted() {
        this.getProfile();
    },
    methods: {
        resetError() {
            this.formErrors = new Error({});
        },
        async getProfile() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/auth/profile`,
                });

                const { data } = response;

                this.formFields = {
                    disability_id: data.disability_id,
                    full_name: data.full_name,
                    email: data.email,
                    phone: data.phone,
                    residential_address: data.residential_address,
                    arrival_address: data.arrival_address,
                    is_phone: data.is_phone,
                    is_wp: data.is_wp,
                    address_type: data.address_type
                };
            } catch (error) {
                this.notifyError();
            }
        },
        async handleSubmit() {
            this.resetError();

            try {
                const response = await request({
                    method: 'post',
                    url: `/auth/profile`,
                    data: this.formFields
                });

                this.notifySuccessWithMsg(`${ this.$t('title.profile') } ${ this.$t('title.updateSuccessfully') }`);

            } catch (error) {

                this.notifyError();

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        loadOptions({ action, searchQuery, callback }) {
            if ( action === ASYNC_SEARCH ) {
                simulateAsyncOperation(() => {
                    this.search(searchQuery)
                    .then((options) => {
                        callback(null, options); // Pass the options to the callback
                    })
                    .catch((error) => {
                        callback(error, []); // Handle errors and pass an empty array
                    });
                });
            }
        },

    }
};
</script>



import Vue from 'vue';
import VueRouter from 'vue-router';
import PersonalDetails from '../view/PersonalDetails.vue';
import OpenVolunteering from '../view/pages/volunteer/OpenVolunteering.vue';
import MyVolunteerBasket from '@/view/pages/volunteer/MyVolunteerBasket.vue';
import NewHelpRequest from '@/view/pages/user/NewHelpRequest.vue';
import UserManagement from '@/view/pages/admin/UserManagement.vue';
import VolunteersManagement from '@/view/pages/admin/VolunteersManagement.vue';
import NotificationLog from '@/view/pages/NotificationLog';
import AboutUs from '@/view/AboutUs.vue';
import Login from '../view/auth/login/index.vue';
import Registration from '../view/auth/registration/index.vue';
import Profile from "../view/auth/profile/index.vue";
import { hasAuthUser, removeStorage, getAuthUser } from "@/Util/auth";
import AdminLogin from "../view/auth/admin/login/index.vue";
import Dashboard from "../view/auth/dashboard/index.vue";
import UserNewHelpRequest from "../view/pages/user/WithoutLoginNewHelpRequest";
import AdminLayout from "../view/layouts/admin";

Vue.use(VueRouter);

//error
import Error from "../views/error";

const ParentComponent = {
    render(h) {
        return h('router-view');
    }
};

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        component: Login,
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            authRequired: true,
        },
        component: Dashboard
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            authRequired: false,
        },
        component: Login,
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration,
    },
    {
        path: '/open-menu',
        name: 'OpenVolunteering',
        meta: {
            authVolunteerRequired: true,
        },
        component: OpenVolunteering,
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: Profile,
    },
    {
        path: '/basket-menu',
        name: 'MyVolunteerBasket',
        meta: {
            authVolunteerRequired: true,
        },
        component: MyVolunteerBasket,
    },
    {
        path: '/help-menu',
        name: 'NewHelpRequest',
        meta: {
            authRequired: true,
        },
        component: NewHelpRequest,
    },
    {
        path: '/notification-menu',
        name: 'NotificationLog',
        meta: {
            authRequired: true,
        },
        component: NotificationLog,
    },
    {
        path: '/user/new-help-request',
        name: 'UserNewHelpRequest',
        meta: {
            authRequired: false,
        },
        component: UserNewHelpRequest,
    },
    {
        path: '/about-menu',
        name: 'AboutUs',
        meta: {
            authRequired: true,
        },
        component: AboutUs,
    },

    //admin
    {
        path: '/admin/login',
        name: 'AdminLogin',
        component: AdminLogin,
        meta: {
            authAdminRequired: false,
        },
    },

    {
        path: '/admin',
        redirect: '/admin/login',
        meta: {
            authAdminRequired: true,
        },
        component: AdminLayout,
        children: [
            {
                path: 'dashboard',
                name: 'AdminDashboard',
                component: Dashboard,
            },
            {
                path: 'notification-menu',
                name: 'AdminNotificationLog',
                component: NotificationLog,
            },
            {
                path: 'profile',
                name: 'AdminProfile',
                component: Profile,
            },
            {
                path: 'management-menu',
                name: 'AdminUserManagement',
                component: UserManagement,
            },
            {
                path: 'volunteers-menu',
                name: 'AdminVolunteersManagement',
                component: VolunteersManagement,
            },

        ],
    },

    //error
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: Error,
    },

];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});


router.beforeEach((to, from, next) => {
    if (
      hasAuthUser()
      && getAuthUser().role !== 'admin' &&
      to.matched.some(record => record.meta.authAdminRequired)
    ) {
        next({
            name: 'adminLogin',
        });
    }

    if (
      hasAuthUser()
      && getAuthUser().role !== 'volunteer' &&
      to.matched.some(record => record.meta.authVolunteerRequired)
    ) {
        next({
            name: 'adminLogin',
        });
    }
    if (
      hasAuthUser()
      && getAuthUser().role == 'admin' &&
      to.matched.some(record => record.meta.authRequired)
    ) {
        next({
            name: '404',
        });
    }
    if (
      ( to.matched.some(record => record.meta.authRequired)
        || to.matched.some(record => record.meta.authAdminRequired)
        || to.matched.some(record => record.meta.authVolunteerRequired) )
      && !hasAuthUser()
    ) {
        removeStorage('auth');
        next({
            name: 'Login',
        });
    }


    next();
});


export default router;

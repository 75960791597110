const titles = {
    action: "פעולה",
    addSuccessfully: "נוסף בהצלחה",
    updateSuccessfully: "עודכן בהצלחה",
    errorMessage: "אופס, משהו השתבש, נסה שוב",
    deletedSuccess: "נמחק בהצלחה",
    otpVerifiedMsg: "קוד אימות אומת בהצלחה!",
    otpSentSuccessMsg: "קוד אימות נשלח בהצלחה!",
    registerSuccessMsg: "נרשמת בהצלחה!",
    areYouSureWantToLogout: "האם אתה בטוח שברצונך להתנתק?",
    successLogin: "נכנסת בהצלחה",
    update: "עדכן",
    save: "שמור",
    item: "פריט",
    profile: "פרופיל",
    dashboard: "לוח בקרה",
    enterToSearch: "הזן לחיפוש",
    email: "אימייל",
    phone: "טלפון",
    openVolunteering: "התנדבות פתוחה",
    myVolunteerBasket: "סל ההתנדבות שלי",
    newHelpRequest: "בקשת עזרה חדשה",
    userManagement: "ניהול משתמשים",
    volunteersManagement: "ניהול מתנדבים",
    notificationLog: "יומן התראות",
    aboutUs: "אודותינו",
    instructions: "הנחיות",
    regulations: "תקנות",
    accessibilityStatement: "הצהרת נגישות",
    fullName: "שם מלא",
    type: "סוג",
    registrationDate: "תאריך רישום",
    disability: "מוגבלות",
    residentialAddress: "כתובת מגורים",
    currentAddress: "כתובת נוכחית",
    alerts: "התראות",
    actions: "פעולות",
    all: "הכל",
    volunteer: "מתנדב",
    user: "משתמש",
    welcomeMessage: "ברוכים הבאים לאפליקציית האפוד הסגול של עמותת נגישות ישראל - לסיוע לאנשים עם מוגבלויות במצבי חירום. כאן תוכלו לסייע לאנשים עם מוגבלויות בכל רחבי הארץ. העזרה שלכם תסייע רבות למשימת האפוד הסגול - לא להשאיר אף אחד מאחור!",
    enterPhoneNumber: "הזן מספר טלפון לקבלת קוד ב-SMS",
    enterVerificationCode: "הזן את קוד האימות",
    verifyCode: "אמת קוד",
    getCode: "קבל קוד",
    registerAs: "הרשם כ",
    clear: "נקה",
    editingMyProfile: "עריכת הפרופיל שלי",
    exitFromSystem: "יציאה מהמערכת",
    refresh: "רענן",
    disableCategoryOption: "השבת אפשרות קטגוריית עזרה למטה ידוע כי בקשת הקטגוריה שלך כבר בתהליך או פתוחה",
    chooseHelpType: "כדי להתחיל, בחר את סוג העזרה שאתה מבקש:",
    toNextStep: "לשלב הבא",
    nextStep: "שלב הבא:",
    needRideTo: "אני צריך הסעה ל",
    iAmFrom: "אני מ",
    originAddressLonger: "כתובת המקור שלך חייבת להיות ארוכה מ-5 תווים.",
    destinationAddressLonger: "כתובת היעד שלך חייבת להיות ארוכה מ-5 תווים.",
    wantToBeThereOn: "אני רוצה להיות שם בתאריך",
    selectFutureDate: "נא לבחור תאריך עתידי.",
    requestDetailsDescription: "פרטים ותיאור הבקשה",
    descriptionLonger: "התיאור שלך חייב להיות ארוך מ-5 תווים.",
    untilRequestRelevant: "עד מתי הבקשה רלוונטית",
    personalDetails: "פרטים אישיים",
    fullNameLonger: "שמך המלא חייב להיות ארוך מ-5 תווים.",
    phoneLonger: "מספר הטלפון שלך חייב להיות ארוך מ-5 תווים.",
    residentialAddressLonger: "כתובת המגורים שלך חייבת להיות ארוכה מ-5 תווים",
    createNewHelpRequest: "צור בקשת עזרה חדשה",
    enterValidPhoneNumber: "הכנס מספר טלפון תקין",
    sentTo: "נשלח אל",
    date: "תאריך",
    time: "זמן",
    channel: "ערוץ",
    content: "תוכן",
    usePrevious: "השתמש קודם",
    useCurrent: "השתמש נוכחי",
    status: "סטטוס",
    sendTo: "שלח אל",
    category: "קטגוריה",
    pendingApplicationUserRequestManagement: "ניהול בקשות משתמשים ממתינים",
    perPage: "לפי עמוד",
    arrivalAddress: "כתובת הגעה",
    wayForCommunication: "דרך תקשורת מועדפת",
    whatsApp: "וואטסאפ",
    welcomeDashboard: "ברוך הבא ללוח המחוונים",
    iNeedARideTo: "אני צריך הסעה ל",
    iWantToBeThereOn: "אני רוצה להיות שם בתאריך",
    at: "בשעה",
    detailsAndDescriptionOfRequest: "פרטים ותיאור הבקשה",
    toTheNextStep: "לשלב הבא",
    referenceDescription: "תיאור הפניה",
    comments: "הערות",
    relevantUntil: "רלוונטי עד",
    sorryICantHelpThisTime: "מצטער, אני לא יכול לעזור הפעם",
    imReadyAccept: "אני מוכן לקבל",
    appliedFilters: "סינונים שהוחלו",
    filtering: "מסנן",
    showVolunteers: "הצג מתנדבים",
    cancel: "בטל",
    byCategory: "לפי קטגוריה",
    byTown: "לפי עיר",
    accessibilityAndDisabilityInfo: "מידע על נגישות וצרכי נכות",
    goBack: "חזור",
    details: "פרטים",
    youCanContactMeAt: "תוכל ליצור איתי קשר ב"
};

export default {
    locale: "he-IL",
    messages: {
        title: titles
    },
};

<template>
    <b-sidebar id="sidebar-1" :visible="true" no-header :backdrop="false" :class="sidebarClass">
        <b-button
            @click="$emit('toggle-sidebar')"
            variant="link"
            class="d-flex ms-auto p-0 mr-2 shadow-none"
            id="toggleSidebar"
        >
            <div class="animated-hamburger open">
                <span></span><span></span><span></span><span></span>
            </div>
        </b-button>
        <div class="overflow-auto">
            <div class="menu mt-1">
                <ul class="px-0 m-0" v-if="!isUnAuthorized">
                    <li>
                        <router-link :to="{ name:'Dashboard' }" class="menu-item" v-if="!$global.hasRole('admin')">
                            <span class="menu-icon">
                            <b-icon :icon="`speedometer2`"></b-icon>
                            </span>
                            <span class="text">{{ this.$t('title.dashboard') }}</span>
                        </router-link>
                        <router-link :to="{ name:'AdminDashboard' }" class="menu-item" v-else>
                            <span class="menu-icon">
                            <b-icon :icon="`speedometer2`"></b-icon>
                            </span>
                            <span class="text">{{ this.$t('title.dashboard') }}</span>
                        </router-link>
                    </li>
                    <li v-for="item in _.filter(menuItems,(i) => !i.roles || (i.roles && _.includes(i.roles, user.role)))"
                        :key="item.id" @click="handleLinkClick">
                        <router-link :to="{ name: `${item.id}` }" class="menu-item">
                            <span class="menu-icon">
                            <b-icon :icon="item.icon"></b-icon>
                            </span>
                            <span class="text">{{ item.text }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="my-1">
                <hr class="m-0 opacity-50" />
            </div>
            <div class="menu">
                <ul class="px-0">
                    <li v-for="item in menuItems2" :key="item.id" @click="handleLinkClick">
                        <router-link :to="{ name: `${item.id}` }" class="menu-item">
                            <span class="menu-icon">
                            <b-icon :icon="item.icon"></b-icon>
                            </span>
                            <span class="text">{{ item.text }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
import { BButton, BSidebar, BIcon } from 'bootstrap-vue';
import { mapGetters, mapState } from "vuex";

export default {
    components: {
        BButton,
        BSidebar,
        BIcon,
    },
    data() {
        return {};
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        menuItems() {
            return [
                {
                    id: 'OpenVolunteering',
                    text: this.$t('title.openVolunteering'),
                    icon: 'envelope-open',
                    roles: ['volunteer']
                },
                { id: 'MyVolunteerBasket', text: this.$t('title.myVolunteerBasket'), icon: 'basket', roles: ['volunteer'] },
                { id: 'NewHelpRequest', text: this.$t('title.newHelpRequest'), icon: 'question-circle', roles: ['user'] },
                { id: 'AdminUserManagement', text: this.$t('title.userManagement'), icon: 'gear', roles: ['admin'] },
                {
                    id: 'AdminVolunteersManagement',
                    text: this.$t('title.volunteersManagement'),
                    icon: 'card-list',
                    roles: ['admin']
                },
                {
                    id: 'AdminNotificationLog',
                    text: this.$t('title.notificationLog'),
                    icon: 'bell',
                    roles: ['admin']
                },
                {
                    id: 'NotificationLog',
                    text: this.$t('title.notificationLog'),
                    icon: 'bell',
                    roles: ['user', 'volunteer']
                },
                { id: 'AboutUs', text: this.$t('title.aboutUs'), icon: 'people' },
            ];
        },
        menuItems2() {
            return [
                { id: 'instructions-menu', text: this.$t('title.instructions'), icon: 'info-circle' },
                { id: 'regulations-menu', text: this.$t('title.regulations'), icon: 'file-earmark-medical' },
                { id: 'accessibility-menu', text: this.$t('title.accessibilityStatement'), icon: 'file-richtext' },
            ];
        },
        sidebarClass() {
            return this.isExpanded
                ? 'custom-sidebar full-width d-flex'
                : 'small-width custom-sidebar d-flex';
        },
        ...mapState(['isUnAuthorized', 'user']),
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
        handleLinkClick() {
            if ( window.innerWidth <= 768 ) {
                this.$emit('toggle-sidebar');
            }
        },
    },
};
</script>

<template>
    <b-button class="animated-button" @click="triggerAnimation" variant="primary">
        {{ text }}
    </b-button>
</template>

<script>
export default {
    name: 'SuperBtn',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    methods: {
        triggerAnimation(event) {
            const button = event.currentTarget;
            button.classList.add('animate');
            setTimeout(() => button.classList.remove('animate'), 600);
            this.$emit('clickBtn')
        },
    },
};
</script>

<style scoped>
.animated-button {
    position: relative;
    padding: 10px 22px;
    border-radius: 6px;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: var(--e-global-color-accent);
    transition: all 0.2s ease;
}

.animated-button:active {
    transform: scale(0.96);
}

.animated-button::before,
.animated-button::after {
    position: absolute;
    content: '';
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    background-repeat: no-repeat;
}

.animated-button.animate::before {
    top: -70%;
    background-image: radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--e-global-color-accent) 20%, transparent 30%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--e-global-color-accent) 15%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%);
    background-size: 10px 10px, 20px 20px, 15px 15px, 20px 20px, 18px 18px, 10px 10px, 15px 15px,
    10px 10px, 18px 18px;
    animation: greentopBubbles ease-in-out 0.6s forwards infinite;
}

@keyframes greentopBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%,
        70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%,
        90% 30%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%,
        90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

.animated-button.animate::after {
    bottom: -70%;
    background-image: radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--e-global-color-accent) 15%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%),
    radial-gradient(circle, var(--e-global-color-accent) 20%, transparent 20%);
    background-size: 10px 10px, 20px 20px, 15px 15px, 20px 20px, 18px 18px, 10px 10px, 15px 15px,
    10px 10px, 18px 18px;
    animation: greenbottomBubbles ease-in-out 0.6s forwards infinite;
}

@keyframes greenbottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}
</style>

<template>
    <div class="request-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.pendingApplicationUserRequestManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :should-show-search-box="false" :columns="columns" :list-url="listUrl"
                            ref="application-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="4" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.category')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.categories"
                                            class="form-input"
                                            :options="dropdowns.categories" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.statuses"
                                            class="form-input"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(userCare)="{item}">
                        {{ ( item.volunteer || {} ).full_name }}
                    </template>
                    <template #cell(urgency)="{item}">
                        <span v-if="( item || {} ).priority == 0" class="text-info">Low</span>
                        <span v-else class="text-danger">High</span>
                    </template>
                    <template #cell(status)="{item}">
                        {{ item.status }}
                    </template>
                    <template #cell(description)="{item}">
                        {{ item.description }}
                    </template>
                    <template #cell(destination_address)="{item}">
                        {{ ( item.origin_address ) ?? ( item.user || {} ).residential_address }}
                    </template>
                    <template #cell(origin_address)="{item}">
                        {{ ( item.destination_address ) ?? ( item.user || {} ).arrival_address }}
                    </template>
                    <template #cell(category)="{item}">
                        {{ ( item.category || {} ).name }}
                    </template>
                    <template #cell(name)="{item}">
                        {{ ( item.user || {} ).full_name }}
                    </template>
                    <template #cell(phone)="{item}">
                        {{ ( item.user || {} ).phone }}
                    </template>
                    <template #cell(email)="{item}">
                        {{ ( item.user || {} ).email }}
                    </template>
                    <template #cell(disability)="{item}">
                        {{ ( item.user.disability || {} ).name }}
                    </template>
                    <template #cell(system_notes)="{item}">
                        {{ ( item || {} ).system_notes }}
                    </template>
                    <template #cell(creation_date)="{item}">
                        {{ $global.utcDateToLocalDate(item.created_at) }}
                    </template>
                    <template #cell(validity)="{item}">
                        {{ item.expiration_date }}
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    { key: 'category', label: 'Category', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'urgency', label: 'Urgency', sortable: true },
    { key: 'userCare', label: 'In User Care', sortable: true },
    { key: 'description', label: 'Description', sortable: true },
    { key: 'validity', label: 'Validity', sortable: true },
    { key: 'creation_date', label: 'Creation Date', sortable: true },
    { key: 'system_notes', label: 'System Notes', sortable: true },
    { key: 'name', label: 'Full Name', sortable: true },
    { key: 'phone', label: 'Phone', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'disability', label: 'Disability', sortable: true },
    { key: 'destination_address', label: 'Residential Address', sortable: true },
    { key: 'origin_address', label: 'Current Address', sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/dashboard/pending-applications',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                categories: [],
                statuses: []
            }
        };
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const { categories, statuses } = response;

                this.dropdowns.categories = categories;
                this.dropdowns.statuses = statuses;

            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>

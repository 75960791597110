import { getAuthUser, setStorage } from "@/Util/auth";

export default {
    data() {
        return {};
    },
    mounted() {
        if ( getAuthUser() && this.user ) {
            if ( this.user?.role !== 'admin' ) {
                this.$router.push({ name: 'Dashboard' });
            } else {
                this.$router.push({ name: 'AdminDashboard' });
            }
        }
    },
    methods: {
        async redirectingUserPage(data) {
            await setStorage("auth", JSON.stringify(data));
            await this.$store.dispatch("user", data);
            await this.$store.dispatch("setUnAuthorized", false);

            if ( data.role == 'admin' ) {
                await this.$router.push({ name: "AdminDashboard" });
            } else {
                await this.$router.push({ name: 'Dashboard' });
            }
        }
    }
};

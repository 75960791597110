<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDetailChangeModal"  :hide-header="true"
                 :hide-footer="true">
            <p>This <b>{{ changeFormFields }}</b> fields have been changed. So what kind of personal details do you want?</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitRequest(true)">{{ $t('title.usePrevious') }}</button>
                <button type="button" class="btn sec-btn col m-0" @click="handleSubmitRequest(false)">
                    {{ $t('title.useCurrent') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpenModal: false,
            changeFormFields : null,
            isUsePreviousDetail: false,
        };
    },
    methods: {
        handleToggleModal(formFields) {
            this.isOpenModal = !this.isOpenModal;
            this.changeFormFields = formFields.join(", ");
        },
        handleSubmitRequest(isUsePreviousDetail) {
            this.$emit('submitNewHelpRequest', isUsePreviousDetail);
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>

<template>
    <div>
        <b-modal v-model="isOpenModal" id="userAssignRoleModal"  :hide-header="true"
                 :hide-footer="true">
            <b-form @submit.prevent="handleSubmitRequest" class="col-12 col-md-12 mx-auto p-0">
                <div class="mb-3">
                    <b-form-group label="Assign Roles">
                        <b-form-checkbox-group
                            v-model="formFields.roles"
                            :options="options"
                            name="roles"
                            stacked
                        >
                        </b-form-checkbox-group>
                    </b-form-group>
                    <b-form-invalid-feedback
                        :state="!formErrors.has('roles')">
                        {{ formErrors.first('roles') }}
                    </b-form-invalid-feedback>
                 </div>
                <div class="modal-footer d-flex mr-2 gap-2">
                    <button type="submit" :disabled="formFields.roles.length > 0 ? false : true" class="btn main-btn col m-0">
                        {{ $t('title.submit') }}
                    </button>
                    <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    roles: []
}
export default {
    data() {
        return {
            isOpenModal: false,
            userId: null,
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            options: [],
        };
    },
    methods: {
        async handleToggleModal(id, roles) {
            this.userId = id;
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/roles`,
                });

                this.options  = response.map((role) => ({
                    text: role.label,
                    value: role.label, // Optional transformation of value
                }));

                this.formFields.roles = roles.map((role) => role.name);

            } catch (error) {
                this.notifyError();
            }

            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/users/update/roles/${ this.userId }`,
                    data: this.formFields
                });
                if(response) {
                    this.notifySuccessWithMsg(`${ this.$t('title.updateRoleMsg') }`);
                    this.$emit('refreshTable');
                    this.cancelModal();
                }
                this.formErrors = new Error({});
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        }
    }
};
</script>
